/* Common styles for desktop and mobile */
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-title-active,
.ant-menu-submenu-title-selected {
  background-color: #ff9700 !important; /* Goldenrod */
  color: white !important;
}

/* Ensure that only the selected menu item or submenu title is highlighted */
.ant-menu-submenu .ant-menu-item-selected,
.ant-menu-submenu .ant-menu-submenu-title-selected {
  background-color: #ff9700 !important; /* Goldenrod */
  color: white !important;
}

/* Remove background color from the entire submenu and nested menus */
.ant-menu-submenu {
  background-color: transparent !important;
}

/* Remove highlighting from non-active submenu titles when a different menu or submenu is selected */
.ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title,
.ant-menu-submenu:not(.ant-menu-submenu-active) .ant-menu-submenu-title {
  background-color: transparent !important;
  color: inherit !important;
}

/* Specific styles for mobile view */
@media (max-width: 767px) {
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-title-active,
  .ant-menu-submenu-title-selected {
    background-color: #ff9700 !important; /* Goldenrod */
    color: white !important;
  }

  /* Ensure only the selected item is highlighted */
  .ant-menu-submenu .ant-menu-item-selected,
  .ant-menu-submenu .ant-menu-submenu-title-selected {
    background-color: #ff9700 !important; /* Goldenrod */
    color: white !important;
  }

  /* Remove background color from the entire submenu and nested menus */
  .ant-menu-submenu {
    background-color: transparent !important;
  }

  /* Remove highlighting from non-active submenu titles when a different menu or submenu is selected */
  .ant-menu-submenu:not(.ant-menu-submenu-selected) .ant-menu-submenu-title,
  .ant-menu-submenu:not(.ant-menu-submenu-active) .ant-menu-submenu-title {
    background-color: transparent !important;
    color: inherit !important;
  }
}

/* Ensure bold text across menu items */
.ant-menu-item,
.ant-menu-title-content {
  font-weight: bold !important;
}
