@keyframes shake-scale {
  0% {
    transform: scale(1) rotate(0deg);
  }
  6% {
    transform: scale(0.9) rotate(0deg);
  }
  10% {
    transform: scale(1.2) rotate(-7deg); 
  }
  14% {
    transform: scale(1.2) rotate(+7deg);
  }
  18% {
    transform: scale(1.2) rotate(-3deg);
  }
  22% {
    transform: scale(1.2) rotate(3deg);
  }
  26% {
    transform: scale(1) rotate(0deg);
  }
  35% {
    transform: scale(1) rotate(0deg);
  }
  40% {
    transform: scale(1) rotate(0deg);
  }
  80% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.shake-scale-animation {
  animation: shake-scale 5.6s ease-in-out infinite;
  animation-delay: 1s;
  animation-play-state: running;
  animation-timing-function: ease;
  animation-direction: normal;
}
